import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { AppRootProps } from '@grafana/data';
import { ROUTES } from '../../constants';
import { Home, Pressure, Chemistry } from '../../pages';

export function App(props: AppRootProps) {
  return (
    <Routes>
      <Route path={`${ROUTES.pressure}/:id?`} element={<Pressure />} />
      <Route path={`${ROUTES.chemistry}/:id?`} element={<Chemistry />} />

      {/* Default page */}
      <Route path="*" element={<Home />} />
    </Routes>
  );
}
