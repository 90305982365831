import { Checkbox, IconButton, InlineField, Toggletip, VerticalGroup, useStyles2 } from '@grafana/ui';
import React, { useEffect, useState } from 'react';
import { bradenheadDescFields, bradenheadTableRows, bradenheadTableCols } from './fields';
import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';
import Timer from './Timer';

export function BradenheadTestForm({ reportState, handleInputChange }) {
  // determine which cols in the bradenhead table should be visible
  const defaultCols = ['bradenheadFlow', 'bradenheadFluid'] // always show the basics.
  const populatedCols = [...new Set(bradenheadTableRows.flatMap(row => // cols containing a value should always be shown. iterate through each row...
    bradenheadTableCols.filter(col => { // then filter out just the cols that have a value
      const value = reportState.data[`${row.prefix}${col.id}`];
      return value !== null && value !== '';
    }).map(col => col.id) // and get their ids. the set removes any duplicates we might have
  ))];
  // state to manage the visible cols
  const [visibleCols, setVisibleCols] = useState([...defaultCols, ...populatedCols]);
  const colSelector = (
    <VerticalGroup>
      {bradenheadTableCols.map(col => (
        <Checkbox
          key={col.id}
          value={visibleCols.includes(col.id)}
          label={col.label}
          onChange={(evt) => toggleColVisibility(col.id, evt.currentTarget.checked)}
        />
      ))}
    </VerticalGroup>
  )

  // timer controls
  const [timerIndex, setTimerIndex] = useState<number>(-1);
  const [progressPercentage, setProgressPercentage] = useState<number>(0);
  const [timerActive, setTimerActive] = useState<boolean>(false);

  // automatically start the timer when either of the first two bradenhead values is updated.
  useEffect(() => {
    // Check if either of the fields has a value and is not null or undefined. only autostart on new records.
    if ((reportState.data['0min_bradenheadFlow'] || reportState.data['0min_bradenheadFluid']) && reportState.id === 'new') {
      // If so, start the timer.
      setTimerActive(true);
    }
  }, [reportState.data['0min_bradenheadFlow'], reportState.data['0min_bradenheadFluid'], reportState.id]);

  const toggleColVisibility = (colId, isChecked) => {
    setVisibleCols(prevVisibleCols => {
      // If the checkbox is checked and the colId is not already in the array, add it
      if (isChecked && !prevVisibleCols.includes(colId)) {
        return [...prevVisibleCols, colId];
      }
      // If the checkbox is not checked, remove the colId from the array
      else if (!isChecked) {
        return prevVisibleCols.filter(id => id !== colId);
      }
      // If the checkbox is checked and the colId is already in the array, just return the previous state
      return prevVisibleCols;
    });
  };

  // define the styles at the last second since they're so data-driven here.
  const getStyles = (theme: GrafanaTheme2) => ({
    thPadding: css({
      padding: '10px',
    }),
    hidden: css({
      display: 'none',
    }),
    activeRow: css({
      outline: `1px dashed ${theme.colors.primary.main};`,
    }),
    progressCell: css({
      background: `linear-gradient(
        to bottom,
        ${theme.colors.warning.transparent} ${progressPercentage}%,
        ${theme.colors.secondary.transparent} ${progressPercentage}%
      )`,
    }),
  });
  const s = useStyles2(getStyles);

  // returns `hidden` css class string or '' if a field should not be visible
  const shouldHide = colId => visibleCols.includes(colId) ? '' : s.hidden

  return (
    <div>
      {bradenheadDescFields.map(({ id, component: FieldComponent, label, tooltip, ...extraFieldProps }) => (
        <InlineField key={id} label={label} labelWidth={28} tooltip={tooltip || ''}>
          <FieldComponent
            aria-label={label}
            className="width-30"
            value={reportState.data[id]}
            onChange={(event) => handleInputChange(`data.${id}`, event)}
            {...extraFieldProps}
          />
        </InlineField>
      ))}
      <table>
        <thead>
          <th className={s.thPadding} style={{width: '90px'}}>
            <Timer {...{timerActive, timerIndex, progressPercentage}} onActiveChange={setTimerActive} onIndexChange={setTimerIndex} onProgressChange={setProgressPercentage} />
          </th>
          {bradenheadTableCols.map(header => (
            <th className={`${s.thPadding} ${shouldHide(header.id)}`} key={header.id}>{header.label}</th>
          ))}
          <th className={s.thPadding}>
            <div style={{display: 'flex'}}>
              <Toggletip title="Available fields" content={colSelector} >
                <IconButton name="plus" tooltip="Show additional fields" />
              </Toggletip>
            </div>
          </th>
        </thead>
        <tbody>
          {bradenheadTableRows.map(({prefix, label}, index) => (
            <tr key={prefix} className={timerIndex === index ? s.activeRow : ''}>
              <td className={`${s.thPadding} ${timerIndex === index ? s.progressCell : ''}`}>{label}</td>
              {bradenheadTableCols.map(({ id, component: FieldComponent, label, tooltip, ...extraFieldProps }) => (
                <td className={`${s.thPadding} ${shouldHide(id)}`} key={prefix+id}>
                  <FieldComponent
                    aria-label={label}
                    value={reportState.data[prefix+id]}
                    onChange={(event) => handleInputChange(`data.${prefix}${id}`, event)}
                    {...extraFieldProps}
                  />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
