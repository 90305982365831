import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';
import { InlineField, useStyles2, Card, InlineFieldRow} from '@grafana/ui';
import { wellDetailsFields, existingPressureFields } from './fields';
import React from 'react';

export function WellDetailsForm({reportState, handleInputChange}) {
  const s = useStyles2(getStyles);

  return (
    <div>
        {wellDetailsFields.map(({ id, component: FieldComponent, label, tooltip, ...extraFieldProps }) => (
          <InlineField
            key={id}
            label={label}
            labelWidth={28}
            tooltip={tooltip || ''}
          >
            <FieldComponent
              aria-label={label}
              className='width-30'
              value={reportState.data[id]}
              onChange={(event) => handleInputChange(`data.${id}`, event)}
              {...extraFieldProps}
            />
          </InlineField>
        ))}
        <Card className={s.matchWidth}>
          <Card.Heading>Existing pressures</Card.Heading>
          <Card.Meta>
            Record all pressures as found.
          </Card.Meta>
          <Card.Description>
            <InlineFieldRow>
            {existingPressureFields.map(({ id, component: FieldComponent, label, tooltip, ...extraFieldProps }) => (
              <InlineField
                key={id}
                label={label}
                labelWidth={18}
                tooltip={tooltip || ''}
                grow
              >
                <FieldComponent
                  aria-label={label}
                  value={reportState.data[id]}
                  onChange={(event) => handleInputChange(`data.${id}`, event)}
                  {...extraFieldProps}
                />
              </InlineField>
            ))}
            </InlineFieldRow>
          </Card.Description>
        </Card>
    </div>
  );
}

const getStyles = (theme: GrafanaTheme2) => ({
  matchWidth: css({ // it doesn't feel right, but as far as i can tell the form elements are set at a fixed width.
    width: '708px',
  }),
});
