import { InlineField, Input, Select, useStyles2 } from '@grafana/ui';
import React from 'react';
import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';
import { TAGS } from '../../constants';
import { generateOptions } from 'utils/utils.formatting';

export function AssessmentForm({ reportState, handleInputChange }) {
  const s = useStyles2(getStyles);

  return (
    <div>
      <InlineField label='Data collector' labelWidth={28} tooltip='The person who was in the field collecting this data.'>
        <Input
          aria-label='Data collector'
          className="width-30"
          value={reportState.data_collector}
          onChange={(event) => handleInputChange('data_collector', event)}
        />
      </InlineField>
      <InlineField label='Report author' labelWidth={28} tooltip="The person who will sign their name to this report. They probably have a PhD or something.">
        <Input
          aria-label='Report author'
          className="width-30"
          value={reportState.author}
          onChange={(event) => handleInputChange('author', event)}
        />
      </InlineField>
      <InlineField label='Status' labelWidth={28} tooltip=''>
        <Select
          aria-label='Status'
          className="width-30"
          value={reportState.status}
          onChange={(event) => handleInputChange('status', event)}
          options={generateOptions(TAGS.pressure)}
        />
      </InlineField>
    </div>
  );
}

const getStyles = (theme: GrafanaTheme2) => ({
  thPadding: css({
    padding: '10px',
  }),
});
