import { css } from '@emotion/css';
import { AppEvents, GrafanaTheme2 } from '@grafana/data';
import { Button, Card, ConfirmButton, FileDropzone, Icon, IconButton, InlineField, useStyles2 } from '@grafana/ui';
import { UploadedItem, reportFields } from './fields';
import React, { useState } from 'react';
import { getAppEvents } from '@grafana/runtime';
import { API_BASE_URL } from '../../constants';

export function DetailsAndUploadForm({reportState, handleInputChange}) {
  const s = useStyles2(getStyles);

  const [showUploader, setShowUploader] = useState(false);

  const [pendingFiles, setPendingFiles] = useState<File[]>([]);
  const uploadPendingFiles = () => {
    const appEvents = getAppEvents(); // used to render the growler in the upper right

    // yeah idk their stupid fucking backendSrv shit wouldn't send files properly, so we'll fall back to fetch...
    // https://github.com/grafana/grafana/issues/71451
    const formData = new FormData();
    pendingFiles.forEach(file => formData.append('file', file, file.name)); // UUID computed on the server
    fetch(`${API_BASE_URL}/files`, {method: 'POST', body: formData}).then(async (response: Response) => {
      // get the json, add to store, and publish a response as needed.
      const payload = await response.json();

      // add new upload items to report's store, then empty out the pending file list
      handleInputChange('data', [...reportState.data, ...(payload?.data?.uploads || [])])
      setPendingFiles([])
      setShowUploader(false)

      // celebrate, or maybe growl meanly
      appEvents.publish({
        type: response.status === 200 ? AppEvents.alertSuccess.name : AppEvents.alertWarning.name,
        payload: [payload?.message || response.statusText],
      });
    });
  }

  return (
    <div>
      {reportFields.map(({ id, component: FieldComponent, label, tooltip, ...extraFieldProps }) => (
        <InlineField
          key={id}
          label={label}
          labelWidth={28}
          tooltip={tooltip || ''}
        >
          <FieldComponent
            aria-label={label}
            className='width-30'
            value={reportState[id]}
            onChange={(event) => handleInputChange(id, event)}
            {...extraFieldProps}
          />
        </InlineField>
      ))}
      <Card className={s.matchWidth}>
        <Card.Heading>Attached documents ({reportState.data.length})</Card.Heading>
        <Card.Description>
          <div>
            {reportState.data.map((item: UploadedItem) => (
              <li className={s.dashlistItem} key={item.id}>
                <div className={s.dashlistLink}>
                  <div className={s.dashlistLinkBody}>
                    <a className={s.dashlistTitle} href={`${API_BASE_URL}/files/${item.id}`} target='_blank' rel="noreferrer">
                      {item.name}
                    </a>
                    <small style={{marginLeft: '5px'}}>({new Date(item.datetime_uploaded).toLocaleDateString()})</small>
                  </div>
                  <ConfirmButton
                    size="sm"
                    confirmText="Delete"
                    confirmVariant="destructive"
                    onConfirm={() => handleInputChange('data', reportState.data.filter((stateItem: UploadedItem) => stateItem !== item))}
                  >
                    <IconButton tooltip='Delete file' name='trash-alt'/>
                  </ConfirmButton>
                </div>
              </li>
            ))}
          </div>
          {reportState.data.length === 0 || showUploader ? (
            <div className={s.uploadZone}>
              <FileDropzone
                onFileRemove={item => setPendingFiles(prevFiles => prevFiles.filter(file => file !== item.file))}
                options={{
                  onDropAccepted: (files: File[]) => setPendingFiles(prevFiles => [...prevFiles, ...files]),
                  maxFiles: 3,
                  maxSize: 5000000,
                  accept: {'application/pdf': ['.pdf'], 'text/csv': ['.csv', '.xls', '.xlsx']}
                }}
              >
                <div className={s.dzChild}>
                  <Icon name="folder-upload" size="lg" />
                  <b style={{marginLeft: '5px', fontSize: '15px'}}>Select files</b>
                </div>
              </FileDropzone>
              <div className={s.uploadButton}>
                <Button icon="upload" disabled={pendingFiles.length === 0} onClick={uploadPendingFiles}>
                  Upload file{pendingFiles.length > 1 && 's'}
                </Button>
              </div>
            </div>
          ) : (
            <div className={s.uploadAgainDiv}>
              <Button fill='text' size='sm' onClick={() => setShowUploader(true)}>+ Add more</Button>
            </div>
          )}
        </Card.Description>
      </Card>
    </div>
  );
}

const getStyles = (theme: GrafanaTheme2) => ({
  matchWidth: css({ // it doesn't feel right, but as far as i can tell the form elements are set at a fixed width.
    width: '708px',
  }),
  uploadZone: css({
    marginTop: theme.spacing(2)
  }),
  uploadButton: css({
    justifyContent: 'end',
    display: 'flex',
    width: '100%',
    marginTop: theme.spacing(2)
  }),
  uploadAgainDiv: css({
    marginTop: theme.spacing(1)
  }),
  dzChild: css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '10px',
  }),
  dashlistLink: css`
    display: flex;
    cursor: pointer;
    border-bottom: 1px solid ${theme.colors.border.weak};
    margin-right: ${theme.spacing(1)};
    padding: ${theme.spacing(1)};
    align-items: center;

    &:hover {
      a {
        color: ${theme.colors.text.link};
        text-decoration: underline;
      }
    }
  `,
  dashlistTitle: css`
    &::after {
      position: absolute;
      content: '';
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
    }
  `,
  dashlistLinkBody: css`
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;
  `,
  dashlistItem: css`
    position: relative;
    list-style: none;
  `,
});
