import React, { useState, useEffect } from 'react';
import { IconButton, Tooltip } from '@grafana/ui';

interface TimerProps {
  timerActive: boolean; // Prop to control the timer start
  onActiveChange: (index: boolean) => void;
  timerIndex: number;
  onIndexChange: (index: number) => void;
  progressPercentage: number;
  onProgressChange: (progress: number) => void;
}

const Timer: React.FC<TimerProps> = ({ timerActive, onActiveChange, timerIndex, onIndexChange, progressPercentage, onProgressChange }) => {
  const [secondsElapsed, setSecondsElapsed] = useState<number>(0);

  useEffect(() => {
    let interval: ReturnType<typeof setInterval> | null = null;

    if (timerActive && secondsElapsed < 30 * 60) {
      interval = setInterval(() => {
        setSecondsElapsed((prevSeconds) => {
          const newSeconds = prevSeconds + 1;
          const newIndex = Math.floor(newSeconds / 300);
          onIndexChange(newIndex);
          const progress = ((newSeconds % 300) / 300) * 100;
          onProgressChange(progress);
          return newSeconds;
        });
      }, 1000);
    }

    if (secondsElapsed >= 30 * 60) {
      if (interval) {
        clearInterval(interval);
      }
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [timerActive, secondsElapsed, onActiveChange, onIndexChange, onProgressChange]);

  const resetTimer = () => {
    setSecondsElapsed(0);
    onIndexChange(-1);
    onProgressChange(0);
    onActiveChange(false);
  };

  const formatTime = (totalSeconds: number): string => {
    const minutes = Math.floor(totalSeconds / 60).toString().padStart(2, '0');
    const seconds = (totalSeconds % 60).toString().padStart(2, '0');
    return `${minutes}:${seconds}`;
  };

  return (
    <div style={{display: 'flex'}}>
      <Tooltip content='For new reports, the timer will automatically begin when you edit the starting row.' show={timerActive ? false : undefined}>
        <span style={{width: '45px'}}>{formatTime(secondsElapsed)}</span>
      </Tooltip>
      {timerActive ? (
        <IconButton aria-label='Reset timer' tooltip='Reset timer' name="repeat" onClick={resetTimer} size='sm'/>
      ) : (
        <IconButton aria-label='Manually start timer' tooltip='Manually start timer' name="play" onClick={() => onActiveChange(true)} size='sm'/>
      )}
    </div>
  );
};

export default Timer;
